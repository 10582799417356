<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-domain"
                                        style="transform: scale(1.7);"></i> Manage your companies</h1>
                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to manage your companies by adding new ones and editing existing ones </label>
                        <div v-if="addingcompany">
                            <button @click="addingcompany = false" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                                
                            <form @submit.prevent="preventsubmit">
                                <div class="form-row">
                                    <b-form-group class="ml-1 mr-1" label="Registration Number" label-for="bankname">
                                        <b-form-input type="text" id="bankname" v-model="company['Matricule']"
                                            ></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="ml-1 mr-1" label="Company Name" label-for="bankname">
                                        <b-form-input type="text" id="bankname" v-model="company['Name']"
                                            ></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="ml-1 mr-1" label="Company Email" label-for="input-sm">
                                        <b-form-input type="email" id="input-sm" v-model="company.Email"
                                            ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="form-row">
                                <b-form-group class="ml-1 mr-1" label="Company Phone number" label-for="phoneNumber">
                                    <b-form-input type="number" id="phoneNumber" v-model="company.Phone"
                                        ></b-form-input>
                                </b-form-group>
                                <b-form-group class="ml-1 mr-1" label="Company Address" label-for="inputAddress">
                                    <b-form-input type="text" id="inputAddress" aria-required="" v-model="company.Address"
                                        ></b-form-input>
                                </b-form-group>
                                <b-form-group class="ml-1 mr-1" label="Website" label-for="inputAddress">
                                    <b-form-input type="text" id="inputAddress" aria-required="" v-model="company.Website"
                                        ></b-form-input>
                                </b-form-group>
                                </div>
                                <div class="mt-5 mb-5 text-center">
                                    <button type="submit" @click="handleSumbit"
                                        class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label class="d-inline-flex align-items-center">New company</label>
                                    <button type="button" class="btn btn-primary ml-2" @click="addingcompany = true"><i
                                            class="mdi mdi-domain-plus" style="transform: scale(1.4);"></i></button>
                                            

                                                
                                                

                                                
                                                    
                                                
                                                
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="companies" :fields="fields" responsive="sm"
                                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered">
                                    <template v-slot:cell(editButton)="data">
                                        <b-button @click="editcompany(data.item)" size="sm" variant="primary"><i style="transform: scale(1.4);" class="mdi mdi-briefcase-edit"></i></b-button>
                                        <b-button style="background-color: brown;" class="ml-1" @click="deletecompany(data.item)" size="sm" variant="primary"><i style="transform: scale(1.4); " class="mdi mdi-delete"></i></b-button>
                                        <b-modal v-model="showModal" title="Edit company">
                                            <div v-if="selectedcompany">
                                                <!-- Display company information inside the modal -->
                                                <div class="row">
                                                    
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="field-1" class="control-label">Company Name</label>
                                                            <input type="text" v-model="selectedcompany['Name']" class="form-control" />
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="field-2" class="control-label">Email</label>
                                                            <input type="email" v-model="selectedcompany.Email" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="field-4" class="control-label">Address</label>
                                                            <input type="text" v-model="selectedcompany.Address" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="field-3" class="control-label">Phone Number</label>
                                                            <input type="numer" v-model="selectedcompany['Phone']" class="form-control" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="field-3" class="control-label">Registration Number</label>
                                                            <input type="numer" v-model="selectedcompany['Matricule']" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <!-- Add more fields here as needed -->
                                            </div>

                                            <template #modal-footer="{ cancel, ok }">
                                                <!-- Modal buttons -->
                                                <b-button @click="cancel">Cancel</b-button>
                                                <b-button @click="saveChanges" variant="primary">Save</b-button>
                                            </template>
                                        </b-modal>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from "./layouts/main";
import axios from "axios"
import swal2 from "sweetalert2"
import swal1 from "sweetalert";
import { read, utils, write } from 'xlsx';
import { EventBus } from "./event-bus";

export default {
    data() {
        return {
            data: [],
            fileUploaded: false,
            dataLabels: [],
            addingcompany: false,
            companies: [],
            company: {
                Name: "",
                Email: "",
                Phone: "",
                Address: "",
                Website: "",
                Matricule: "",
            },


            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "Matricule",
                    sortable: true,

                },
                {
                    key: "Name",
                    sortable: true,
                },
                {
                    key: "Address",
                    sortable: true,
                },
                {
                    key: "Email",
                    sortable: true,
                },
                {
                    key: "Phone",
                    sortable: true,
                },
                {
                    key: "Website",
                    sortable: true,
                },
                {
                    key: 'editButton', label: '',
                    tdClass: 'edit-column'
                }
            ],
            selectedcompany: null,
            showModal: false
        }
    },
    components: {
        Layout,
    },
    computed: {
        rows() {
            return this.companies.length;
        },
    },
    async mounted() {
        await this.getcompanies()
    },
    methods: {
        // async handleFileUpload() {
        //     const input = document.createElement("input");
        //     input.type = "file";
        //     input.accept = ".xlsx, .xls";
        //     input.style.display = "none";
        //     input.addEventListener("change", async (event) => {
        //         const selectedFile = event.target.files[0];
        //         if (selectedFile) {
        //             const file = selectedFile;
        //             if (file) {
        //                 this.fileUploaded = true
        //                 const readFileAsync = (file) => {
        //                     return new Promise((resolve, reject) => {
        //                         const reader = new FileReader()
        //                         reader.onload = (e) => resolve(e.target.result)
        //                         reader.onerror = (e) => reject(e)
        //                         reader.readAsBinaryString(file)
        //                     });
        //                 };

        //                 try {
        //                     const data = await readFileAsync(file)
        //                     const workbook = read(data, { type: 'binary' })
        //                     const sheetName = workbook.SheetNames[0]
        //                     const sheet = workbook.Sheets[sheetName]
        //                     this.data = utils.sheet_to_json(sheet, { header: 1 })
        //                     //this.dataLabels = this.data[0]
        //                     this.dataLabels = this.data[0].map(column => column.trim())
        //                     this.data[0].push('Societe');
        //                     const dataModel = ['Client Name', 'Email', 'Phone Number', 'Address']
        //                     if (JSON.stringify(this.dataLabels) !== JSON.stringify(dataModel)) {
        //                         swal({
        //                             title: 'Error',
        //                             text: `Fields missmatch, please use our template.`,
        //                             icon: 'error',
        //                         })
        //                         this.data = []
        //                         return
        //                     }
        //                     this.data.shift()
        //                     console.log(this.data)
        //                     this.data.forEach(async (row, index) => {
        //                         let newClient= {}
        //                         newClient["Societe"]= localStorage.getItem("societe").slice(1, -1)
        //                         newClient["Client Name"] = row[0]
        //                         newClient["Email"] = row[1]
        //                         newClient["Phone Number"] = row[2]
        //                         newClient["Address"] = row[3]
        //                         try {
        //                                 await axios.post('https://finex.4help.tn/api/v1/addclient', newClient).then(async (res) => {
        //                                     if (res.status === 200) {
        //                                         swal({
        //                                             title: 'Success',
        //                                             text: `Successfully added new company: ${newClient["Client Name"]} !`,
        //                                             icon: 'success',
        //                                         });
        //                                         await this.getClients()
        //                                         this.addingClient = false
        //                                         this.currentPage = 1
        //                                         this.client = {
        //                                             "Client Name": "",
        //                                             Email: "",
        //                                             "Phone Number": "",
        //                                             Address: ""
        //                                         }

        //                                     }
        //                                 })
        //                             } catch (error) {
        //                                 if (error.response && error.response.status === 409) {
        //                                     swal({
        //                                         title: 'Conflict',
        //                                         text: `Client already exists, please try again.`,
        //                                         icon: 'error',
        //                                     });
        //                                 }
        //                                 else {
        //                                     swal({
        //                                         title: 'Error',
        //                                         text: `Oops! something went wrong.`,
        //                                         icon: 'error',
        //                                     });
        //                                 }
        //                             }
        //                     })
        //                     this.initselectedIndexes(this.data.length)
        //                 } catch (error) {
        //                     console.error(error)
        //                     // Handle errors here
        //                 }
        //             }
        //         }
        //     });

        //     input.click();

        // },
        saveChanges() {
            const matricule = this.selectedcompany.Matricule
            const newsociete = this.selectedcompany
            axios.put(`https://finex.4help.tn/api/v1/societes/${matricule}`, newsociete).then((res) => {
                if (res.status === 200) {
                    swal1({
                        title: 'Success',
                        text: `Successfully updated company: ${this.selectedcompany["Name"]} !`,
                        icon: 'success',
                    })
                }else{
                    swal1({
                        title: 'Error',
                        text: `Oops! something went wrong.`,
                        icon: 'error',
                    })
                }
            }).then(() => {
              this.showModal = false
            this.getcompanies()  
            })
            
        },
        editcompany(company) {
            // Implement your logic for editing the client here
            this.selectedcompany = { ...company }; // Clone the client object to avoid modifying the original data
            this.showModal = true; // Show the modal
        },
        preventsubmit(e) {
            e.preventDefault()
        },
        async handleSumbit() {
            try {
                await axios.post('https://finex.4help.tn/api/v1/societes', this.company).then(async (res) => {
                    if (res.status === 200 || res.status === 201) {
                        EventBus.$emit('refresh-navbar',true)
                        swal1({
                            title: 'Success',
                            text: `Successfully added new company: ${this.company["Name"]} !`,
                            icon: 'success',
                        });
                        await this.getcompanies()
                        this.addingcompany = false
                        this.currentPage = 1
                        this.company = {
                            "Name": "",
                            Email: "",
                            "Phone": "",
                            Address: "",
                            Website: "",
                            Matricule: ""
                            
                        }
                        

                    }
                })
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    swal1({
                        title: 'Conflict',
                        text: `Company already exists, please try again.`,
                        icon: 'error',
                    });
                }
                else {
                    swal1({
                        title: 'Error',
                        text: `Oops! something went wrong.`,
                        icon: 'error',
                    });
                }
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async deletecompany(company) {
            swal2.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                
            }).then(async (result) => {
                
                if (result.isConfirmed) {
                    await axios.delete(`https://finex.4help.tn/api/v1/societes/${company.Matricule}`).then(async (res) => {
                        if (res.status === 200) {
                            EventBus.$emit('refresh-navbar',true)
                            await this.getcompanies()
                            swal2.fire(
                                'Deleted!',
                                'the company has been deleted.',
                                'success'
                            )
                        }else{
                            swal2.fire(
                                'Error',
                                'Something went wrong.',
                                'error'
                            )
                        }
                    })
                }
            })
            
        },
        async getcompanies() {
            this.companies = await axios.get(`https://finex.4help.tn/api/v1/societes`).then(res => { return res.data })
        },
    }
}

</script>

<style scoped>
h1 {
    font-size: 28px;
    margin-bottom: 20px;
}</style>